<template>
  
  <div class = "container">
    
    <CallError v-if="!isLoading && error!=null" :warning="error"/>

    <LoadingFullPage v-if="isLoading && !error"/>

    <TwoFaForm v-if="!isLoading && !error" :sec="secLeft"/>

  </div>

</template>

<script>

import TwoFaForm from '@/components/Auth/TwoFaForm'
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import CallError from '@/components/UI/CallError'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  components: { LoadingFullPage, CallError, TwoFaForm },
  setup(){

    const { store , router, isLoading, error } = useBoilerplate(true);

    const secLeft = ref(null);

    const getSecsLeft = async () => {
      try {
        
        const url = '/api/getSecsLeft';
        const res = await fetch(url);

        if(res.status == 200){
          const data = await res.json();
          secLeft.value = data.seconds;
          return isLoading.value = false;
        }
        else if(res.status == 412){
          store.state.logUserOut();
          return router.push({name: 'Auth'});
        }else{
          isLoading.value = false;
          return error.value = "Error de Sitema";
        }

      } catch (err) {
        console.log(err);
        isLoading.value = false;
        error.value = "Error de Conexion";
      }
    }

    onMounted(() => getSecsLeft());

    return { error, isLoading, secLeft };
  
  }
}
</script>
<template>
  
    <div class="row center-align maxDiv">
      <div class="col s12 center-align orange marginTop">
          <h5 class=" white-text">
              2FA
          </h5>
      </div>
      <div class="col s12 left-align grey darken-1 white-text">
          <p>
              Inserte en el espacio abajo el codigo de seguridad que acabamos de enviarle por correo.
          </p>
      </div>
      <div class="col s12 center-align grey darken-2">
          <br>
          <form @submit.prevent="sendCode">
            <div class="input-field marginTop">
              <input type="text" v-model="twoFaCode">
              <label for="twoFCode">Codigo (4 digitos)</label>
            </div>
            <h5 class = 'marginTop grey-text text-lighten-1'>(<b>{{ secLeft }}</b> s)</h5>
            <div class="col s12 center-align grey darken-2">
              <Loader v-if="isLoading" />
              <Error v-if="error && !isLoading" :warning="error"/>
              <p>
                <button class="btn orange white-text marginBottom marginTop" id = 'twoFAtrigger'>
                    <i class="fas fa-check"></i>
                </button>
              </p>
            </div>
          </form>
      </div>
    </div>

</template>

<script>

import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { post } from '@/composables/httpCalls'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    props: ['secs'],
    components: { Loader, Error },
    
    setup(props){
        
        const {isLoading, router, error } = useBoilerplate(false);
        
        const secLeft = ref(180);
        const twoFaCode = ref('');
        let globInterval = null;

        const countDown = () => {
            
            if(secLeft.value < 1){
                clearInterval(globInterval);
                store.state.logUserOut();
                router.push({name: "Auth"});
            }
            secLeft.value--;
        }

        const sendCode = async () => {
            
            try{
                
                error.value = null;
                isLoading.value = true;

                const url = '/api/checkMyCode';
                const vars = {code: twoFaCode.value };
                const res = await post(url, vars);
                
                if(res.status == 200){
                    clearInterval(globInterval);
                    return router.push({name: "Exchange"});
                }else if(res.status == 412){
                    clearInterval(globInterval);
                    return router.push({name: "Auth"});
                }else if(res.status == 404){
                    error.value = "El Codigo No Es Correcto";
                    isLoading.value = false;
                }else{
                    error.value = "Error de Sistema";
                    isLoading.value = false;
                }
            }catch(err){
                isLoading.value = false;
                error.value = "Error de Conexion";
            }
        }

        onMounted(() => globInterval = setInterval(countDown, 1000));

        return { error, isLoading, secLeft, twoFaCode, sendCode };
  
  }
}
</script>